import * as Sentry from '@sentry/react'
import env from '@/enviroments'

Sentry.init({
  dsn: `${env.sentryDSN}` || '',
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      unblock: ['.sentry-unblock, [data-sentry-unblock]'],
      unmask: ['.sentry-unmask, [data-sentry-unmask]'],
    }),
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})
